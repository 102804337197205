import '../../App.css'
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
function Dashboard() {
    const navigate = useNavigate();

    return (
        <div className='grid grid-cols-12 relative mt-[20px] lg:mt-[15px] lg:px-[17px] ' >
            <div className='col-span-12 lg:col-span-6 px-[16px] lg:px-[13px]'>
                <div className='mb-[1.5rem] w-full'>
                    <div className='font-bold text-[0.938rem] leading-[23px] bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] px-[10px] py-[5px] text-[#ffffff] rounded-tl-[calc(.25rem-1px)] rounded-br-[0] rounded-tr-[calc(.25rem-1px)] rounded-bl-[0]'>
                        Live Sports Profit
                    </div>
                    <div className='p-[0px flex-auto] w-full border-[1px] border-[#0000002d] bg-[#ffffff] flex items-center justify-center'>
                        <svg version="1.1" className="highcharts-root" xmlns="http://www.w3.org/2000/svg" width="398" height="400" viewBox="0 0 398 400" role="img" aria-label="">
                            <desc>Created with Highcharts 11.1.0</desc>
                            <defs>
                                <filter id="highcharts-drop-shadow-1">
                                    <feDropShadow dx="1" dy="1" floodColor="#000000" floodOpacity="0.75" stdDeviation="2.5"></feDropShadow>
                                </filter>
                                <clipPath id="highcharts-io213m8-4-">
                                    <rect x="0" y="0" width="378" height="375" fill="none"></rect>
                                </clipPath>
                            </defs>
                            <rect fill="#ffffff" className="highcharts-background" filter="none" x="0" y="0" width="398" height="400" rx="0" ry="0"></rect>
                            <rect fill="none" className="highcharts-plot-background" x="10" y="10" width="378" height="375" filter="url(#highcharts-drop-shadow-1)"></rect>
                            <rect fill="none" className="highcharts-plot-border" data-z-index="1" stroke="#cccccc" strokeWidth="0" x="10" y="10" width="378" height="375"></rect>
                            <g className="highcharts-series-group" data-z-index="3" filter="none">
                                <g className="highcharts-series highcharts-series-0 highcharts-pie-series highcharts-tracker" data-z-index="0.1" opacity="1" transform="translate(10,10) scale(1 1)" filter="none" clipPath="none">
                                    <path fill="none" d="M 189 10 A 177.5 177.5 0 1 1 188.82250002958324 10.000088749992614 M 189 187.5 A 0 0 0 1 0 189 187.5" className="highcharts-empty-series" strokeWidth="1" stroke="#cccccc"></path>
                                </g>
                                <g className="highcharts-markers highcharts-series-0 highcharts-pie-series" data-z-index="0.1" opacity="1" transform="translate(10,10) scale(1 1)" clipPath="none"></g>
                            </g>
                            <text x="199" textAnchor="middle" className="highcharts-title" data-z-index="4" y="25"></text>
                            <text x="199" textAnchor="middle" className="highcharts-subtitle" data-z-index="4" y="24"></text>
                            <text x="10" textAnchor="start" className="highcharts-caption" data-z-index="4" y="397"></text>
                            <g className="highcharts-data-labels highcharts-series-0 highcharts-pie-series highcharts-tracker" data-z-index="6" opacity="1" transform="translate(10,10) scale(1 1)"></g>
                            <g className="highcharts-legend highcharts-no-tooltip" data-z-index="7" visibility="hidden">
                                <rect fill="none" className="highcharts-legend-box" rx="0" ry="0" stroke="#999999" strokeWidth="0" filter="none" x="0" y="0" width="8" height="8"></rect>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
            <div className='col-span-12 lg:col-span-6 px-[16px] lg:px-[13px]  '>
                <div className='mb-[1.5rem] w-full'>
                    <div className='font-bold text-[0.938rem] leading-[23px] bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] px-[10px] py-[5px] text-[#ffffff] rounded-tl-[calc(.25rem-1px)] rounded-br-[0] rounded-tr-[calc(.25rem-1px)] rounded-bl-[0]'>
                        Backup Sports Profit
                    </div>
                    <div className='p-[0px flex-auto] w-full border-[1px] border-[#0000002d] bg-[#ffffff] flex items-center justify-center'>
                        <svg version="1.1" className="highcharts-root" xmlns="http://www.w3.org/2000/svg" width="398" height="400" viewBox="0 0 398 400" role="img" aria-label="">
                            <desc>Created with Highcharts 11.1.0</desc>
                            <defs>
                                <filter id="highcharts-drop-shadow-1">
                                    <feDropShadow dx="1" dy="1" floodColor="#000000" floodOpacity="0.75" stdDeviation="2.5"></feDropShadow>
                                </filter>
                                <clipPath id="highcharts-io213m8-4-">
                                    <rect x="0" y="0" width="378" height="375" fill="none"></rect>
                                </clipPath>
                            </defs>
                            <rect fill="#ffffff" className="highcharts-background" filter="none" x="0" y="0" width="398" height="400" rx="0" ry="0"></rect>
                            <rect fill="none" className="highcharts-plot-background" x="10" y="10" width="378" height="375" filter="url(#highcharts-drop-shadow-1)"></rect>
                            <rect fill="none" className="highcharts-plot-border" data-z-index="1" stroke="#cccccc" strokeWidth="0" x="10" y="10" width="378" height="375"></rect>
                            <g className="highcharts-series-group" data-z-index="3" filter="none">
                                <g className="highcharts-series highcharts-series-0 highcharts-pie-series highcharts-tracker" data-z-index="0.1" opacity="1" transform="translate(10,10) scale(1 1)" filter="none" clipPath="none">
                                    <path fill="none" d="M 189 10 A 177.5 177.5 0 1 1 188.82250002958324 10.000088749992614 M 189 187.5 A 0 0 0 1 0 189 187.5" className="highcharts-empty-series" strokeWidth="1" stroke="#cccccc"></path>
                                </g>
                                <g className="highcharts-markers highcharts-series-0 highcharts-pie-series" data-z-index="0.1" opacity="1" transform="translate(10,10) scale(1 1)" clipPath="none"></g>
                            </g>
                            <text x="199" textAnchor="middle" className="highcharts-title" data-z-index="4" y="25"></text>
                            <text x="199" textAnchor="middle" className="highcharts-subtitle" data-z-index="4" y="24"></text>
                            <text x="10" textAnchor="start" className="highcharts-caption" data-z-index="4" y="397"></text>
                            <g className="highcharts-data-labels highcharts-series-0 highcharts-pie-series highcharts-tracker" data-z-index="6" opacity="1" transform="translate(10,10) scale(1 1)"></g>
                            <g className="highcharts-legend highcharts-no-tooltip" data-z-index="7" visibility="hidden">
                                <rect fill="none" className="highcharts-legend-box" rx="0" ry="0" stroke="#999999" strokeWidth="0" filter="none" x="0" y="0" width="8" height="8"></rect>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>

        </div >

    );
}

export default Dashboard;
