import '../../App.css'
import { useState, useEffect } from 'react';
import { Modal, Input, DatePicker, Table } from 'antd';
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import qs from 'qs';

function MarketAnalysis() {
    const location = useLocation();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(true)
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [type, setType] = useState('settle')
    const showAgeModal = () => {
        setIsOpen(true);
    };
    const onChange = (date, dateString) => {
        console.log(date, dateString);
    };
    let locale = {
        emptyText: 'No data!',
    };
    const columns = [
        {
            title: 'User Name',
            dataIndex: 'name',
            sorter: true,
            render: (name) => `${name.first} ${name.last}`,
        },
        {
            title: 'SportName',
            dataIndex: 'name',
            sorter: true,
        },
        {
            title: 'Event',
            dataIndex: 'name',
            sorter: true,
        },
        {
            title: 'Market',
            dataIndex: 'name',
            sorter: true,
        },
        {
            title: 'Selection',
            dataIndex: 'name',
            sorter: true,
        },
        {
            title: 'Type',
            dataIndex: 'name',
            sorter: true,
        },
        {
            title: 'Odds Req.',
            dataIndex: 'name',
            sorter: true,
        },
        {
            title: 'Stack',
            dataIndex: 'name',
            sorter: true,
        },
        {
            title: 'Place Time',
            dataIndex: 'name',
            sorter: true,
        },
        {
            title: 'Settle Time',
            dataIndex: 'name',
            sorter: false,
        },

    ];
    const getRandomuserParams = (params) => ({
        results: params.pagination?.pageSize,
        page: params.pagination?.current,
        ...params,
    });
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const fetchData = () => {
        setLoading(true);
        fetch(`https://randomuser.me/api?${qs.stringify(getRandomuserParams(tableParams))}`)
            .then((res) => res.json())
            .then(({ results }) => {
                // setData(results);
                setLoading(false);
                setTableParams({
                    ...tableParams,
                    pagination: {
                        ...tableParams.pagination,
                        total: 200,
                    },

                });
            });
    };
    // useEffect(() => {
    //     fetchData();
    // }, [tableParams.pagination?.current, tableParams.pagination?.pageSize]);
    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setData([]);
        }
    };

    const changeSelectOptionHandler = (event) => {
        setType(event.target.value);
    };


    return (
        <div></div>
    )
}


export default MarketAnalysis;
